@import "@/styles/global.scss";

.layout-app-wrapper {
    position: relative;
    height: 100vh;

    @media (min-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // padding-top: 50px;
        height: 100%;
    }
}
