.notFound{
    padding: 50px;

    img{
        width: 100%;
    }

    h1{
        margin-top: 40px;
        text-align: center;
        font-weight: 400;

        span{
            color: #3030fa;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}