#kyc-form{
    margin: 27px 22px 44px;

    .link{
        #action-button{
            background-color: #fdc84e;
        }
    }

    .share-button{
        &#whatsapp{
            background-color: #25D366;
        }
        &#linkedin{
            background-color: #0072b1;
        }
        i{
            font-size: 20px;
            margin-right: 10px;
        }
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 52px;
        color: white;
        padding: 15px;
        border-radius: 26px;
        cursor: pointer;
        font-weight: 500;
        transition: 300ms;
        font-weight: 400;
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        margin-bottom: 10px;
    }
}