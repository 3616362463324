@import "@/styles/variables.scss";

#post-page {
  padding: 0 $padding-x;
  // margin-top: 16px;
  margin-top: 30px;

  .post-title {
    h2 {
      font-weight: 400;
      text-align: center;
    }
    h5 {
      text-align: center;
      max-width: 316px;
      margin: 5px auto 20px;
    }
  }

  .filename {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    margin-top: 3px;
  }

  .submit-button {
    margin-top: 44px;
  }

  .fonticon {
    margin-left: 10px;
  }

  .select-group {
    h4 {
      margin-bottom: 12px;
    }

    &__tabs {
      display: flex;
      flex-wrap: wrap;
      .select-tab {
        transition: $transition-speed;
        margin-right: 16px;
        margin-bottom: 16px;
        display: flex;
        padding: 8px 10px;
        height: 35px;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        width: fit-content;
        border: 1px solid #a5a5a5;

        &.--selected {
          background: #146a89;
          color: white;
        }
      }
    }
  }

  .textarea-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    h4.label {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 400;
      display: flex;
    }
    textarea {
      max-height: 102px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: $transition-speed;
      padding: 28px 14px;
      box-sizing: border-box;
      color: #6a6a7d;
      outline: none;
      -webkit-appearance: none;
      box-shadow: none !important;

      &:focus {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 12px;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 12px;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 12px;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 12px;
      }
    }
  }

  .rti--container {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    outline: none !important;
    box-shadow: none !important;
    font-family: "Open Sans", sans-serif !important;
    padding: 10px 10px !important;
    &:focus {
      box-shadow: none !important;
      outline: none !important;
      border: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
  }

  .rti--tag {
    padding: 3px 10px;
  }

}
.access-control-wrapper{
    display: flex;
    gap: 15px;
    margin-bottom: 12px;
    justify-content: flex-start;
    & > div {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    span{
        align-self: center;
        font-family: "Open Sans", sans-serif;
        font-size: 10px;
    }
}

.upload-wrapper {
  height: 150px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-image: url("../../assets/svgs/upload.svg");
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: center;
  cursor: pointer;
  background-position: center;
  background-origin: content-box;
  padding: 17px;
  padding-bottom: 25px;
  position: relative;

  h5{
    position: absolute;
    bottom: 3px;
  }

  &.__active {
    background-image: url("../../assets/svgs/uploaded.svg");
  }

}
