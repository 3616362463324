@import "@/styles/global.scss";

$background-color-normal: rgb(133, 132, 255);
$background-color-hover: #3030fa;
$background-color-active: #3d3de0;
$background-color-disabled: #3d3de050;

#action-button{
    background: $background-color-normal;
    text-align: center;
    width: 100%;
    height: 52px;
    color: white;
    padding: 15px;
    border-radius: 26px;
    cursor: pointer;
    font-weight: 500;
    transition: 300ms;

    .spinner{
        .askrefer-circular-loader{
            margin: 0 auto;
        }
    }

    &:hover{
        background: $background-color-hover;
    }

    &:active{
        background: $background-color-active;
    }


    &.--disabled{
        background: $background-color-disabled;
        &:hover, &:active{
            background: $background-color-disabled;
        }
    }
}