#refer-form {
    margin: 27px 22px 44px;

    form {
        div.input__group.--refer {
            .refer_input__wrapper {
                position: relative;
                input {
                    width: 100%;
                    padding-right: 130px;
                    text-decoration: underline;
                }
                .copy-button {
                    width: 118px;
                    cursor: pointer;
                    height: 52px;
                    background-color: #8080f0;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: calc(100% - 118px);
                    h5 {
                        color: white;
                        font-weight: 300;
                    }

                    .share-dropdown{
                        opacity: 1;
                        background: white;
                        position: absolute;
                        top: 55px;
                        width: 100%;
                        box-shadow: 2px 2px 5px #e4e4e4;
                        border-radius: 5px;
                        padding-top: 5px;
                        padding-bottom: 10px;
                        
                        h5{
                            transition: 300ms;
                            color: black;
                            padding: 10px 10px;
                            &:hover{
                                background: rgba(0, 0, 0, 0.151);
                            }
                            a{
                                text-decoration: none;
                                color: black;

                            }
                        }
                    }
                }
            }
        }
    }
}
