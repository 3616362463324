@import '@/styles/variables.scss';

#overlay-component{
    height: 100vh;
    width: 100vw;
    // position: absolute;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #00000080;
    display: flex;
    flex-direction: column-reverse;
    transition: $transition-speed;
    z-index: 10;

    
    .overlay-component{
        &__slider{
            overflow-y: auto !important;
            transition: $transition-speed;
            background-color: white;
            border-radius: 8px 8px 0 0;
            max-height: 80%;
            height: fit-content;
            width: 100%;
        }
    }

    &.--hidden{
        background-color: #00000000;
        visibility: hidden;

        .overlay-component{
            &__slider{
                max-height: 0;
            }
        }
    }
}