#dashboard-page {
  padding: 10px 50px;
  font-family: "Open Sans", sans-serif;

  .active__candidate{
    text-decoration: underline;
    color: purple;
  }
  .header__tab {
    margin-bottom: 20px;
    margin-top: 20px;

    display: flex;
    justify-content: space-between;
  }

  .header__dropdown {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  .header__select {
    flex-basis: 80%;
  }

  .candidates__applied {
    align-items: center;
    & > p {
      margin-top: 40px;
      margin-bottom: 10px;
      text-align: center;
      font-weight: bold;
    }

    .one__candidate {
      display: flex;
      justify-content: space-between;
    }

    .one__candidate{
      margin-bottom: 10px;
    }
    
    .candidate__name {
      font-size: 14px;
      cursor: pointer;
    }
    .candidate__actions {
      display: flex;
      gap: 15px;
      font-size: 12px;
    }

    .actions__accept {
      background: green;
      color: white;
      padding: 2px 11px;
      border-radius: 2px;
    }
    .actions__reject {
      background: red;
      color: white;
      padding: 2px 11px;
      border-radius: 2px;
      cursor: pointer;
    }
  }

  .options__dropdown {
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 15px;
    z-index: 10;

    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

    .dd-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      cursor: pointer;
      padding: 0 10px;

      span {
        white-space: nowrap;
      }
    }
  }
}
