// Colors
$primary-blue: #5A59FF;

// Spacing
$padding-x: 16px;

// timing
$transition-speed: 300ms;

$fast-animation-speed: 100ms;
$slow-animation-speed: 500ms;
