@import '@/styles/variables.scss';

$light-blue: #F3F6FD;
$white: #ffffff;

#landing-page{
    padding: 0 $padding-x;
    height: calc(100% - 65px);
    // background: $light-blue;
    background: linear-gradient($white 15%, $light-blue);
    background: -moz-linear-gradient(top,  $white 15%, $light-blue); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop($white 15%), color-stop($light-blue)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $white 15%, $light-blue); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $white 15%,$light-blue); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $white 15%,$light-blue); /* IE10+ */
    background: linear-gradient(to bottom, $white 15% , $light-blue); /* W3C */
    section.header{
        padding: 20px 35px;
        margin-bottom: 10px;
        img{
            // margin-left: 70px;
            height: 221px;
            width: 100%;
            margin: 0px auto;
            margin-top: 50px;
            margin-bottom: 30px;
        }
        h2{
            margin-top: 10px;
            font-size: 28px;
            text-align: center;
            font-weight: 400;
            margin-bottom: 10px;
        }
        h4{
            text-align: center;
            font-size: 15px;
        }
    }
    section.questions{
        padding: 17px 20px;
        margin-bottom: 32px;
        background: $light-blue;
        display: flex;
        justify-content: space-between;
        gap: 15px;

        div{
            width: 144px;
            height: 47px;
            width: 100%;
        }
    }
    section.cta{
        h5{
            text-align: center;
            font-weight: 600;
            margin-bottom: 24px;
        }
    }
}