.slider-form{
    .header-group{
        margin-bottom: 33px;
        .slider-form__header{
            font-weight: 600;
            margin-bottom: 10px;
        }
        .slider-form__subheader{
            line-height: 20px;
            font-weight: 400;
            span{
                color: #3A6DDE;
            }
        }
    }

    form{
        .input__group{
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            label{
                font-size: 12px;
                color: black;
                margin-bottom: 5px;
            }

            input{
                -webkit-appearance: none;
                box-shadow: none !important;
                height: 52px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                padding: 17px 12px;
                outline: none;
                border-radius: 5px;

                &:focus {
                    outline: none;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                }
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    font-size: 12px;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    font-size: 12px;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    font-size: 12px;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    font-size: 12px;
                }
            }
        }
    }
}