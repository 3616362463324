@import '@/styles/variables.scss';

#navbar-wrapper{
    width: 100%;
    nav{
        padding: 10px $padding-x;
        box-shadow: 0px 1px 2px #00000014;
        
        a{
            cursor: pointer;
            width: fit-content;
            text-decoration: none;
        }
    }
}