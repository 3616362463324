@import "@/styles/variables.scss";

.close__icon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
#post-card {
  width: 100%;
  font-family: "Open Sans", sans-serif !important;
  .top-nav {
    height: 136px;
    width: 100%;
    background: #fdc84d;
    position: relative;
  }

  .content-body {
    position: relative;
    top: -40px;

    .poster_details {
      padding-left: $padding-x;
      padding-right: $padding-x;
      display: flex;
      margin-bottom: 25px;
      flex-direction: column;
      gap: 10px;
      &__img {
        border: 3px solid white;
        color: white;
        height: 120px;
        width: 120px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        margin-right: 10px;
        h4 {
          font-weight: bold;
          letter-spacing: 1px;
        }
      }
      .extra_details {
        h3 {
          font-weight: bold;
          margin-bottom: 3px;
        }
      }
    }

    .stats_details {
      padding-left: $padding-x;
      padding-right: $padding-x;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      .one_detail {
        h2 {
          font-weight: 500;
        }
      }
    }

    .action_details {
      display: flex;
      color: white;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      flex-direction: column;

      .action_button {
        padding: 8px;
        cursor: pointer;
        border-radius: 5px;
        transition: $transition-speed;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;

        &.--no {
          background: #fd4e4e;
          &:hover {
            background: #ff1818;
          }
        }
        &.--maybe {
          background: #eaa70d;
          &:hover {
            background: #d69b10;
          }
        }
        &.--yes {
          background: #3dc34d;
          &:hover {
            background: #13c028;
          }
        }
      }
    }

    .desire_details_wrapper {
      background: #f6f6f6;
      padding-top: 10px;
      padding-bottom: 10px;

      .desire_details {
        padding: $padding-x;
        min-height: 20px;
        background: white;

        h4 {
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 15px;
        }
      }
    }

    .attachment_details_wrapper {
      background: #f6f6f6;
      padding-top: 10px;
      padding-bottom: 10px;

      .attachment_details {
        padding: $padding-x;
        min-height: 20px;
        background: white;

        h4 {
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 15px;
        }

        .attachments {
          margin-top: 15px;
          height: 270px;
          overflow: hidden;
          cursor: pointer;
          .pdf__dummy {
            margin-top: 13px;
            width: 200px;
            height: 250px;
            background: #ebebeb;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 8px;

            span{
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .pdf__viewercontainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    .react-pdf__Page__annotations {
      display: none;
    }
    .react-pdf__Page__canvas {
      width: 90vw !important;
      object-fit: contain !important;
      height: auto !important;
    }

    .close__icon {
      position: absolute;
      top: 25px;
      left: 25px;
      cursor: pointer;
    }

    .pagination__wrapper {
      display: flex;
      justify-content: space-between;
      color: #7d4fe0;
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      margin-top: 10px;

      .navigation {
        display: flex;
        text-decoration: underline;
        gap: 15px;
        cursor: pointer;
        span {
          height: 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }

      .pages {
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .endpreview__button {
    #action-button {
      border-radius: 0 !important;
      position: absolute;
      bottom: 0;
    }
  }
}
